<script lang='ts'>
import Container from '@igefa-e-commerce/pattern-library/src/components/Layout/Container.svelte'
import type { Customer } from 'buy-user-application-domain/model/Customer.ts'
import MyAccountIcon from '../../atoms/MyAccountIcon/MyAccountIcon.svelte'
import LevejoLogoM from './logos/LevejoLogoM.svelte'
import LevejoLogoXs from './logos/LevejoLogoXs.svelte'

export let customer: Customer
</script>

<div class='reduced-header'>
  <Container marginBottom='none'>
    <div class='container'>
      <div class='left-elements'>
        <div class='brand'>
          <a href='/' class='mobile'>
            <LevejoLogoXs />
          </a>
          <a href='/' class='desktop'>
            <LevejoLogoM />
          </a>
        </div>
      </div>
      <div class='right-elements'>
        <div class='controls-container'>
          <MyAccountIcon loggedIn={customer !== undefined} {customer} />
        </div>
      </div>
    </div>
  </Container>
</div>

<style lang='scss'>
  @use '@igefa-e-commerce/pattern-library/src/css/colors.scss';
  @use '@igefa-e-commerce/pattern-library/src/css/spacers.scss';
  @use '@igefa-e-commerce/pattern-library/src/css/breakpoint-mixins.scss';

  $gapSpace: spacers.$space-2xs;
  $smallGapSpace: spacers.$space-3xs;

  .reduced-header {
    padding-bottom: spacers.$space-m-l;

    & > :global(.layout--container) {
      border-bottom: 1px solid colors.$color-neutral-040;
      padding-left: spacers.$space-xl !important;
      padding-top: spacers.$space-4xs-xs !important;
      padding-right: spacers.$space-xl !important;
      padding-bottom: 0;
    }
  }

  .container {
    display: grid;
    gap: $smallGapSpace;
    grid-template-columns: repeat(2, auto);
    justify-content: space-between;
    align-items: center;
    min-height: 4rem;
    box-sizing: border-box;
  }

  .left-elements {
    padding-left: spacers.$space-xs;
  }

  .right-elements {
    padding-right: spacers.$space-xs;

    .controls-container {
      :global(button) {
        margin-bottom: 0;
      }

      :global(error) {
        display: block;
        overflow: hidden;
        width: 2.5em;
      }
    }
  }

  .left-elements,
  .right-elements {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .controls-container {
    display: flex;
    gap: $smallGapSpace;
    justify-content: flex-end;
  }

  .brand {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding-right: $smallGapSpace;

    > a {
      width: fit-content;
    }

    & :global(a svg) {
      width: 100%;
      height: 100%;
      min-width: calc(spacers.$space-2xl + spacers.$space-xs);
    }

    & .desktop {
      display: none;
    }

    & .mobile {
      display: flex;
    }
  }

  @media (min-width: 480px) {
    .checkout-header {
      & > :global(.layout--container) {
        padding: initial;
      }
    }

    .container {
      gap: $smallGapSpace;
    }

    .controls-container {
      gap: $gapSpace;
    }

    .brand {
      padding-right: $gapSpace;
    }

    .brand {
      & :global(a svg) {
        height: spacers.$space-xl;
        width: auto;
      }
    }
  }

  @include breakpoint-mixins.respond-to(xl) {
    .checkout-header {
      & > :global(.layout--container) {
        padding-inline: clamp(0.94rem, 0.49rem + 2.29vw, 3.19rem);
      }
    }

    .container {
      grid-template-columns: repeat(2, 0.3fr);
      min-height: 5.125rem;
      padding: spacers.$space-2xs 0;

      .left-elements,
      .right-elements {
        padding: 0;
      }
    }

    .brand {
      & .desktop {
        display: flex;
      }

      & .mobile {
        display: none;
      }

      & :global(a svg) {
        max-height: spacers.$space-xl;
      }
    }
  }
</style>
